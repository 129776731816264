<template>
    <div class="funds-details-contain">
        <Navbar title="明细记录" />
        <div class="detail-box">
            <div
                class="fund-details"
                v-for="item in 8"
                :key="item"
            >
                <ul>
                    <!-- <li>
                <span class="title">订单编号</span>
                <span class="inner">2023021254521015</span>
            </li> -->
                    <li>
                        <span class="title">操作类型</span>
                        <span class="inner">{{dataObject.borrowmoney || '转账'}}</span>
                    </li>
                    <li>
                        <span class="title">金额</span>
                        <span class="inner">{{dataObject.stage || 0}}元</span>
                    </li>
                    <li>
                        <span class="title">收款人</span>
                        <span class="inner">{{dataObject.stage || '测试'}}</span>
                    </li>
                    <li>
                        <span class="title">操作时间</span>
                        <span class="inner">{{dataObject.repayment_money || '2023-05-22 12:00:00'}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="fund-tro">
        <ul>
            <li v-for="item in dataObject.fund" :key="item"><span>第 {{item.repayment_qi}} 期</span><span>￥{{item.repayment_money}}</span><span>{{item.repayment_day}}</span></li>
        </ul>
    </div> -->
    </div>
</template>

<script>
import Navbar from "../components/NavBar.vue";
export default {
    components: {
        Navbar,
    },
    data() {
        return {
            dataObject: {},
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let res = await this.axios.get("/user/fund");
            if (res.data.success) {
                this.dataObject = res.data.data;
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import "./assets/css/my-zjmx.less";
.detail-box {
    width: 100%;
    padding-top: 50px;

}
.funds-details-contain{
    /deep/.nav-bar-contain{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

    }
}
</style>